import React, { useEffect, useRef, useState } from "react";
import styles from "./Header.module.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {  Closeicon, Hamburger } from "../../Svg";
import { IoIosArrowDown } from "react-icons/io";
import Popup from "../popup/Popup";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import LogoutConfirmation from "../logout/logout";
import { resetAndSetFilterValues, searchData , getAllShippingCountryWithoutPagination} from "../../redux/reducers/productSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import api from "../../utils/Api";
import toast from "../../utils/toast";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css"; // Import default styles
import getSymbolFromCurrency from 'currency-symbol-map'
import ReactCountryFlag from "react-country-flag";
import countries from 'i18n-iso-countries';
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));




const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    overlap: "rectangular",
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Header = ({ cartCount }) => {
  
  const dispatch = useDispatch()

  const currency =  localStorage.getItem("currency") || "INR" 
  const country =  localStorage.getItem("country") || "India" 


  const [searchText, setSearchText] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(currency);
  const [conversionRate, setConversionRate] = useState(
    parseFloat(localStorage.getItem("rate")) || 1
  );
  const [searchItems, setSearchItems] = useState([])
  const [currencies, setCurrencies] = useState()

  console.log('first',currencies)
  console.log('selectedCurrency',selectedCurrency)


  useEffect(() => {
    const fetchAllCountries = async() => {
      try {
        const {data} = await dispatch(getAllShippingCountryWithoutPagination()).unwrap()
        if(data) {
          setCurrencies(data)
        }
      } catch (error) {
        console.error("error fetching currency")
      }
    }
    fetchAllCountries()
  },[dispatch])

  const fetchCurrencyRate = async (currency) => {
    try {
        const { data, status } = await api.currencyConverter();
        const rate = data?.data?.data?.rate || 1;

        if (currency?.currencyCode !== "INR") {
            setConversionRate(rate);
            localStorage.setItem("currency", "USD");
            localStorage.setItem("country",currency?.country)
            localStorage.setItem("tax",currency?.tax)
            localStorage.setItem("rate", rate);
        } else {
            setConversionRate(1);
            localStorage.setItem("currency", "INR");
            localStorage.setItem("country",currency?.country)
            localStorage.removeItem("rate");
            localStorage.removeItem("tax")
        }
    } catch (error) {
        toast.error("Something went wrong");
        console.log("Error fetching currency rate:", error);
    }
};

const handleChange = async (currency) => {
    setSelectedCurrency(currency?.country);
    localStorage.setItem("currency", currency?.currencyCode);
    await fetchCurrencyRate(currency);

    // Reload after setting currency in localStorage and updating the rate
    window.location.reload();
};




  const [showSuggestions, setShowSuggestions] = useState(false);

  const containerRef = useRef(null);

  const handleSearchClick = () => {
    setShowSuggestions((prev) => !prev); // Toggle suggestions visibility

  };
  

  const handleClickOutsides = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowSuggestions(false); // Close suggestions if clicking outside
    }
  };

  useEffect(()=> {

   const getSearchData = async() => {
    if(searchText === null){
      return 
    }else{
  
    const result = await dispatch(searchData(searchText)).unwrap()

    if(result.data) {
       setSearchItems(result.data)
    }
    }
   }
   getSearchData()
  },[searchText])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsides);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsides);
    };
  }, []);
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    if (!showSuggestions) {
      setShowSuggestions(true);
    }
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // State for each dropdown menu item
  const [isBabyDropdownOpen, setIsBabyDropdownOpen] = useState(false);
  const [isToddlerDropdownOpen, setIsToddlerDropdownOpen] = useState(false);
  const [isKidsDropdownOpen, setIsKidsDropdownOpen] = useState(false);
  const [isTrendingDropdownOpen, setIsTrndingDropdownOpen] = useState(false);
  // const [isTwinsDropdownOpen, setIsTwinsDropdownOpen] = useState(false);

  // Toggle for the main menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };



  const categories = {
    "Baby Boys": ["Overall-Sets","Bodysuits","Tops","Bottoms","Rompers-And-Jumpsuits","Jackets-And-Coats","Accessories"],
    "Baby Girls": ["Overall-Sets","Bodysuits","Tops","Bottoms","Dresses","Rompers-And-Jumpsuits","Jackets-And-Coats","Accessories"],
    "Baby Twins" : ["Overall-Sets","Bodysuits","Accessories"],

    "Toddler Boys": ["Overall-Sets","Bodysuits","Tops","Bottoms","Rompers-And-Jumpsuits","Jackets-And-Coats","Accessories"],
    "Toddler Girls": ["Overall-Sets","Bodysuits","Tops","Bottoms","Dresses","Rompers-And-Jumpsuits","Jackets-And-Coats","Accessories"],
    "Toddler Twins" : ["Overall-Sets","Bodysuits"],

    "Kids Boys": ["Overall-Sets","Bodysuits","Tops","Bottoms","Rompers-And-Jumpsuits","Jackets-And-Coats","Accessories"],
    "Kids Girls": ["Overall-Sets","Bodysuits","Tops","Bottoms","Dresses","Rompers-And-Jumpsuits","Jackets-And-Coats","Accessories"],
    "Kids Twins" : ["Overall-Sets","Bodysuits","Accessories"],

  };

  
  const renderSuggestions = () => (
    <>
      {searchItems?.length > 0 ? (
        <div className={styles.suggestions}>
          {searchItems.slice(0, 10).map((name, index) => (
            <div
              key={index}
              className={styles.suggestionItem}
              onClick={() => handleProductClick(name)}
            >
              <span
                style={{
                  cursor: "pointer",
                  color: "#7E7E7E",
                  fontSize: "14px",
                  letterSpacing: "0.05rem",

                }}
              >
                {name.replace(/-/g, ' ')}
              </span>
            </div>
          ))}
        </div>
      ) : 
      null
      }
    </>
  );
  
  
    const handleProductClickMobile = async (nameOrCategory,event) => {
      console.log("nameOrCategory:", nameOrCategory); 
      try {  
        const resultAction = await dispatch(resetAndSetFilterValues({ searchInput: nameOrCategory,page:1}));
        toggleMenu()
        unwrapResult(resultAction); 
        setShowSuggestions(false);
        navigate('/category product');
        window.scrollTo(0,0)
        
      } catch (error) {
        console.error('Failed to fetch product:', error);
      }
    };
    

  const handleProductClick = async (nameOrCategory) => {
    console.log("nameOrCategory:", nameOrCategory); 
    try {

      const resultAction = await dispatch(resetAndSetFilterValues({ searchInput: nameOrCategory,page:1}));
      unwrapResult(resultAction); 
      setShowSuggestions(false);
      navigate('/category product');
      window.scrollTo(0,0)
      
      
    } catch (error) {
      console.error('Failed to fetch product:', error);
    }
  };
  
  

  const location = useLocation();
  const cartData = useSelector((state) => state.cart.cartData);
  const cartItemCount =
    cartData?.reduce((total, item) => total + item.count, 0) || 0;

  const [isPopupOpen, setIsPopupOpen] = useState(false); // State for popup
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false); // State for logout dialog
  const [sidebar, setSidebar] = useState(false); 
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  

  const toggleSidebar = () => setSidebar(!sidebar);
  const handlenav = () => setSidebar(false);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setSidebar(false);
    }
    
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handlePopupOpen = () => setIsPopupOpen(true);
  const handlePopupClose = () => setIsPopupOpen(false);

  // Logout dialog functions
  const handleLogoutClick = () => setIsLogoutDialogOpen(true);
  const handleLogoutConfirm = () => {
    localStorage.clear();
    setIsLogoutDialogOpen(false);
    navigate("/");
  };
  const handleLogoutCancel = () => setIsLogoutDialogOpen(false);

  const menuItems = [
    { path: "/My Profile", label: "My Profile", icon: <MyProfileIcon /> },
    {
      path: "/Order history",
      label: "Order History",
      icon: <OrderHistoryIcon />,
    },
    { path: "/Wishlist", label: "Wishlist", icon: <WishlistIcon /> },
    { path: "/Shopping Cart", label: "Shopping Cart", icon: <Shopping /> },
    { path: "/My Address", label: "Address", icon: <AddressIcon /> },
    { path: "/", label: "Log-out", icon: <LogoutIcon />,action: handleLogoutClick },

    // { path: "Log-out", label: "Log-in",  icon: < />, action: handleLogoutClick }, // Logout with action to open dialog
  ];



 
  return (
    <>
   
      <div className={styles.container}>
        <div className={styles.mainHeader}>
          <div className={styles.menuflex}>
          <div>
      {/* Hamburger icon to toggle the menu */}
      <div onClick={toggleMenu} className={styles.menuIcon}>
        <Hamburger className={styles.hamburger} />
      </div>

      {isMenuOpen && (
        <div className={styles.menu}>
          {/* Header with close button */}
          <div className={styles.menuHeader}>
            <span className={styles.menuTitle}>Menu</span>
            <button className={styles.closeButton} onClick={toggleMenu}>
              <img src={require("../../assets/images/Vector44.png")} alt="Close" style={{ width: "25px", height: "25px" }} />
            </button>
          </div>

          {/* Menu items with individual dropdowns */}
          <ul className={styles.menuItems}>
          <li onClick={() => {handleProductClickMobile("")}}>
              ALL 
           
            </li>
            <li>
  <div
    className={styles.dropdownTrigger}
    onClick={() => setIsBabyDropdownOpen(!isBabyDropdownOpen)}
  >
    BABY <span><IoIosArrowDown className={styles.dropdown1} /></span>
  </div>
  {isBabyDropdownOpen && (
    <div
      className={styles.dropdownContent}
      onClick={(e) => e.stopPropagation()} // Prevent the dropdown from closing
    >
      <Accordion allowZeroExpanded>
        {/* Baby-Boys Subcategory */}
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Boys <IoIosArrowDown className={styles.arrowIcon} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className={styles.subMenu}>
              {categories["Baby Boys"].map((item, index) => (
                <li
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click event
                    handleProductClickMobile(`${item}-Baby-Boys`, e);
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

        {/* Baby-Girls Subcategory */}
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Girls <IoIosArrowDown className={styles.arrowIcon} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className={styles.subMenu}>
              {categories["Baby Girls"].map((item, index) => (
                <li
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click event
                    handleProductClickMobile(`${item}-Baby-Girls`, e);
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

        {/* Baby-Twins Subcategory */}
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Twins <IoIosArrowDown className={styles.arrowIcon} />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <ul className={styles.subMenu}>
                {categories["Baby Twins"].map((item, index) => (
                  <li
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent parent click event
                      handleProductClickMobile(`${item}-Baby-Twins`, e);
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
      </Accordion>
    </div>
  )}
</li>


<li>
  <div
    className={styles.dropdownTrigger}
    onClick={() => setIsToddlerDropdownOpen(!isToddlerDropdownOpen)}
  >
    TODDLER <span><IoIosArrowDown className={styles.dropdown1} /></span>
  </div>
  {isToddlerDropdownOpen && (
    <div
      className={styles.dropdownContent}
      onClick={(e) => e.stopPropagation()} // Prevent the dropdown from closing
    >
      <Accordion allowZeroExpanded>
     
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Boys <IoIosArrowDown className={styles.arrowIcon} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className={styles.subMenu}>
              {categories["Toddler Boys"].map((item, index) => (
                <li
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click event
                    handleProductClickMobile(`${item}-Toddler-Boys`, e);
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

     
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Girls <IoIosArrowDown className={styles.arrowIcon} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className={styles.subMenu}>
              {categories["Toddler Girls"].map((item, index) => (
                <li
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click event
                    handleProductClickMobile(`${item}-Toddler-Girls`, e);
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Twins <IoIosArrowDown className={styles.arrowIcon} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className={styles.subMenu}>
              {categories["Toddler Twins"].map((item, index) => (
                <li
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click event
                    handleProductClickMobile(`${item}-Toddler-Twins`, e);
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )}
</li>
<li>
  <div
    className={styles.dropdownTrigger}
    onClick={() => setIsKidsDropdownOpen(!isKidsDropdownOpen)}
  >
    KIDS <span><IoIosArrowDown className={styles.dropdown1} /></span>
  </div>
  {isKidsDropdownOpen && (
    <div
      className={styles.dropdownContent}
      onClick={(e) => e.stopPropagation()} // Prevent the dropdown from closing
    >
      <Accordion allowZeroExpanded>
     
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Boys <IoIosArrowDown className={styles.arrowIcon} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className={styles.subMenu}>
              {categories["Kids Boys"].map((item, index) => (
                <li
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click event
                    handleProductClickMobile(`${item}-Kids-Boys`, e);
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

     
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Girls <IoIosArrowDown className={styles.arrowIcon} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className={styles.subMenu}>
              {categories["Kids Girls"].map((item, index) => (
                <li
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click event
                    handleProductClickMobile(`${item}-Kids-Girls`, e);
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Twins <IoIosArrowDown className={styles.arrowIcon} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className={styles.subMenu}>
              {categories["Kids Twins"].map((item, index) => (
                <li
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent click event
                    handleProductClickMobile(`${item}-Kids-Twins`, e);
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )}
</li>
            <li onClick={() => setIsTrndingDropdownOpen(!isTrendingDropdownOpen)}>
              <div className={styles.dropdownTrigger}>
              TRENDING <span><IoIosArrowDown className={styles.dropdown1} /></span>
              </div>
              {isTrendingDropdownOpen && <div className={styles.dropdownContent}>   <h4 className={styles.title23} onClick={() => {handleProductClickMobile("Ethnic - Boys")}}>Birthday Combos</h4>     
              <h4 className={styles.title23}  onClick={() => {handleProductClickMobile("Ethnic - Girls")}}>Festive Customization</h4>  </div>}
            </li>
          </ul>
        </div>
      )}
    </div>
    <a href="/">
      <img 
        src="../../assets/images/logo.png" 
        alt="Logo" 
        style={{
          maxWidth: "130px",
          height: "auto",
        }} 
      />
    </a>
          </div>
          <div className={`${styles.opened} ${styles.rightNav} `}>
            <NavLink className={styles.navmenu} onClick={handlenav} to="/">
              HOME
            </NavLink>
            <div className={`${styles.pages} ${styles.navmenu}`}>
              <NavLink
                className={styles.navmenu}
                onClick={() => {
                  handlenav()
                  handleProductClick("small baby")
                }}
              >
              BABY  {" "}
              </NavLink>
              <IoIosArrowDown className={styles.dropdown} />
                 <div className={styles.list}>
                       <div className={styles.contentus}>
  <div className={styles.submenu}>
        <h4
          className={styles.title23}
          onClick={() => {
            handleProductClick("Baby-Boys");
          }}
        >
          Boys
        </h4>
        {/* Sub-Submenu for Boys */}
        <div className={styles.submenuList}>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Overall-Sets-Baby-Boys")}
          >
            Overall sets
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bodysuits-Baby-Boys")}
          >
           Bodysuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Tops-Baby-Boys")}
          >
          Tops
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bottoms-Baby-Boys")}
          >
           Bottoms
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Rompers-And-Jumpsuits-Baby-Boys")}
          >
        Rompers And Jumpsuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Jackets-And-Coats-Baby-Boys")}
          >
           Jackets And Coats
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Accessories-Baby-Boys")}
          >
           Accessories
          </h5>
        </div>

        
      </div>  

      <div className={styles.submenu}>
        <h4
          className={styles.title23}
          onClick={() => {
            handleProductClick("Baby-Girls");
          }}
        >
          Girls
        </h4>
        <div className={styles.submenuList}>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Overall-Sets-Baby-Girls")}
          >
            Overall Sets
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bodysuits-Baby-Girls")}
          >
            Bodysuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Tops-Baby-Girls")}
          >
            Tops
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bottoms-Baby-Girls")}
          >
            Bottoms
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Dresses-Baby-Girls")}
          >
            Dresses
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Rompers-And-Jumpsuits-Baby-Girls")}
          >
            Rompers And Jumpsuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Jackets-And-Coats-Baby-Girls")}
          >
            Jackets And Coats
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Accessories-Baby-Girls")}
          >
            Accessories
          </h5>
        </div>
      </div>   
      <div className={styles.submenu}>
        <h4
          className={styles.title23}
          onClick={() => {
            handleProductClick("Baby-Twins");
          }}
        >
          Twins
        </h4>
        <div className={styles.submenuList}>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Overall-Sets-Baby-Twins")}
          >
            Overall sets
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bodysuits-Baby-Twins")}
          >
            Bodysuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Accessories-Baby-Twins")}
          >
            Accessories
          </h5>
        </div>
      </div>             
                 </div>
              </div>
            </div>
            <div className={`${styles.pages} ${styles.navmenu}`}>
              <NavLink
                className={styles.navmenu}
                onClick={() => {
                  handlenav()
                 handleProductClick("Toddler")
                }}
              >
              TODDLER {" "}
              </NavLink>
              <IoIosArrowDown className={styles.dropdown} />
                 <div className={styles.list}>
                       <div className={styles.contentus}>
                       <div className={styles.submenu}>
        <h4
          className={styles.title23}
          onClick={() => {
            handleProductClick("Toddler-Boys");
          }}
        >
          Boys
        </h4>
        {/* Sub-Submenu for Boys */}
        <div className={styles.submenuList}>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Overall-Sets-Toddler-Boys")}
          >
            Overall sets
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bodysuits-Toddler-Boys")}
          >
           Bodysuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Tops-Toddler-Boys")}
          >
          Tops
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bottoms-Toddler-Boys")}
          >
           Bottoms
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Rompers-And-Jumpsuits-Toddler-Boys")}
          >
        Rompers And Jumpsuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Jackets-And-Coats-Toddler-Boys")}
          >
           Jackets And Coats
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Accessories-Toddler-Boys")}
          >
           Accessories
          </h5>
        </div>

        
      </div>  

      <div className={styles.submenu}>
        <h4
          className={styles.title23}
          onClick={() => {
            handleProductClick("Toddler-Girls");
          }}
        >
          Girls
        </h4>
        <div className={styles.submenuList}>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Overall-Sets-Toddler-Girls")}
          >
            Overall sets
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bodysuits-Toddler-Girls")}
          >
            Bodysuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Tops-Toddler-Girls")}
          >
            Tops
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bottoms-Toddler-Girls")}
          >
            Bottoms
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Dresses-Toddler-Girls")}
          >
            Dresses
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Rompers-And-Jumpsuits-Toddler-Girls")}
          >
            Rompers And Jumpsuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Jackets-And-Coats-Toddler-Girls")}
          >
            Jackets And Coats
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Accessories-Toddler-Girls")}
          >
            Accessories
          </h5>
        </div>
      </div>   
      <div className={styles.submenu}>
        <h4
          className={styles.title23}
          onClick={() => {
            handleProductClick("Toddler-Twins");
          }}
        >
          Twins
        </h4>
        <div className={styles.submenuList}>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Overall-Sets-Baby-Girls")}
          >
            Overall sets
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bodysuits-Baby-Girls")}
          >
            Bodysuits
          </h5>
        </div>
      </div> 

                 </div>
              </div>
            </div>
            <div className={`${styles.pages} ${styles.navmenu}`}>
              <NavLink
                className={styles.navmenu}
                onClick={() => {
                  handlenav()
                 handleProductClick("kids")
                }}
              >
              KIDS{" "}
              </NavLink>
              <IoIosArrowDown className={styles.dropdown} />
                 <div className={styles.list}>
                       <div className={styles.contentus}>
                             <div className={styles.submenu}>
        <h4
          className={styles.title23}
          onClick={() => {
            handleProductClick("Kids-Boys");
          }}
        >
          Boys
        </h4>
        <div className={styles.submenuList}>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Overall-Sets-Kids-Boys")}
          >
            Overall sets
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bodysuits-Kids-Boys")}
          >
            Bodysuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Tops-Kids-Boys")}
          >
            Tops
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bottoms-Kids-Boys")}
          >
            Bottoms
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Rompers-And-Jumpsuits-Kids-Boys")}
          >
            Rompers And Jumpsuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Jackets-And-Coats-Kids-Boys")}
          >
            Jackets And Coats
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Accessories-Kids-Boys")}
          >
            Accessories
          </h5>
        </div>
      </div> 
      <div className={styles.submenu}>
        <h4
          className={styles.title23}
          onClick={() => {
            handleProductClick("Kids-Girls");
          }}
        >
          Girls
        </h4>
        <div className={styles.submenuList}>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Overall-Sets-Kids-Girls")}
          >
            Overall sets
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bodysuits-Kids-Girls")}
          >
            Bodysuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Tops-Kids-Girls")}
          >
            Tops
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bottoms-Kids-Girls")}
          >
            Bottoms
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Dresses-Kids-Girls")}
          >
            Dresses
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Rompers-And-Jumpsuits-Kids-Girls")}
          >
            Rompers And Jumpsuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Jackets-And-Coats-Kids-Girls")}
          >
            Jackets And Coats
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Accessories-Kids-Girls")}
          >
            Accessories
          </h5>
        </div>
      </div>    
       <div className={styles.submenu}>
        <h4
          className={styles.title23}
          onClick={() => {
            handleProductClick("Kids-Twins");
          }}
        >
          Twins
        </h4>
        <div className={styles.submenuList}>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Overall-Sets-Kids-Twins")}
          >
            Overall sets
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Bodysuits-Kids-Twins")}
          >
            Bodysuits
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Accessories-Kids-Twins")}
          >
            Accessories
          </h5>
        </div>         
                 </div>
                 </div>
              </div>
            </div>
           
            <div className={`${styles.pages} ${styles.navmenu}`}>
  {/* Main Menu Item */}
  <NavLink
    className={styles.navmenu}
    onClick={() => {
      handlenav();
      handleProductClick("TRENDING");
    }}
  >
    TRENDING
  </NavLink>
  <IoIosArrowDown className={styles.dropdown} />

  {/* Submenu for Main Menu */}
  <div className={styles.list}>
    <div className={styles.contentus}>
      {/* Submenu 1 - Boys */}
      <div className={styles.submenu}>
        <h4
          className={styles.title23}
          onClick={() => {
            handleProductClick("Birthday-Combos");
          }}
        >
         Birthday Combos
        </h4>
        {/* Sub-Submenu for Boys */}
        {/* <div className={styles.submenuList}>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Ethnic - Boys - Shirts")}
          >
            Shirts
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("")}
          >
            Pants
          </h5>
        </div> */}
      </div>

      {/* Submenu 2 - Girls */}
      <div className={styles.submenu}>
        <h4
          className={styles.title23}
          onClick={() => {
            handleProductClick("Festive-Customization");
          }}
        >
         Festive Customization
        </h4>
        {/* Sub-Submenu for Girls */}
        {/* <div className={styles.submenuList}>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Ethnic - Girls - Dresses")}
          >
            Dresses
          </h5>
          <h5
            className={styles.submenuItem}
            onClick={() => handleProductClick("Ethnic - Girls - Accessories")}
          >
            Accessories
          </h5>
        </div> */}
      </div>
    </div>
  </div>
</div>

            {/* <div className={`${styles.pages} ${styles.navmenu}`}>
              <NavLink
                className={styles.navmenu}
                onClick={() => {
                  handlenav()
                 handleProductClick("Twins")
                }}
             
              >
                TWINS{" "}
              </NavLink>
              <IoIosArrowDown className={styles.dropdown} />
                 <div className={styles.list}>
                       <div className={styles.contentus}>
                          <h4 className={styles.title23} onClick={() => {handleProductClick("Twins - Boys")}}>Boys</h4>     
                          <h4 className={styles.title23} onClick={() => {handleProductClick("Twins - Girls")}}>Girls</h4>  
                          <h4 className={styles.title23} onClick={() => {handleProductClick("Twins - Boys and Girls")}}>Boys & Girls</h4>   
                 </div>
              </div>
            </div> */}
          </div>

          <div
            ref={menuRef}
            className={sidebar ? ` ${styles.mobilenav}` : styles.hide}
          >
            <div className={styles.menucontainer} onClick={toggleSidebar}>
              <div className={styles.iconclose}>
                <Closeicon />
              </div>
              <div className={styles.navgstion}>
                <p className={styles.navplan}>Navigation</p>
                {menuItems.map((item) =>
                  item.action ? (
                    <li
                      key={item.label}
                      className={styles.profilemenuItem}
                      onClick={item.action}
                    >
                      <div className={styles.profileicon}>{item.icon}</div>
                      <span>{item.label}</span>
                  
                    </li>
                  ) : (
                    <Link
                      key={item.path}
                      to={item.path}
                      style={{ textDecoration: "none" }}
                    >
                      <li
                        className={`${styles.profilemenuItem} ${
                          location.pathname === item.path
                            ? styles.profileactive
                            : ""
                        }`}
                      >
                        <span className={styles.profileicon}>{item.icon}</span>
                        {item.label}
                      </li>
                    </Link>
                  )
                )}
              </div>
            </div>
          </div>

          <div className={styles.icons}>
          <IconButton onClick={() => navigate("/category product")}>
            <div className={styles.searchContainer}>
              <div className={styles.searchIcon} onClick={handleSearchClick}>
                <img
                  src={require("../../assets/images/Vector3.png")}
                  alt="search icon"
                  style={{ width: "25px", height: "25px" }}
                />
              </div>
            </div>
            </IconButton>

            <div className={styles.search}>
              <IconButton aria-label="cart" onClick={handlePopupOpen}>
                <StyledBadge badgeContent={cartItemCount} color="success">
                  <img
                    src={require("../../assets/images/Vector2.png")}
                    alt=""
                    style={{
                      width: "25px",
                      height: "25px",
                      paddingBottom: "10px",
                    }}
                  />
                </StyledBadge>
              </IconButton>
            </div>
            <div className={styles.search} >
            <IconButton onClick={() => navigate("/My Profile")}>
              <Link  className={styles.profileIcon}>
                <img
                  src={require("../../assets/images/Vector1.png")}
                  alt=""
                  style={{ width: "25px", height: "25px" }} onClick={() => setSidebar(true)}
                />
              </Link>
              </IconButton>
            </div>
            <img
              className={styles.mobile_profileIcon}
                  src={require("../../assets/images/Vector1.png")}
                  alt=""
                  style={{ width: "25px", height: "25px" }} onClick={() => setSidebar(true)}
                />
              <span>{cartCount}</span>
          </div>
        </div>

        {/* Popup Component */}
        <Popup isOpen={isPopupOpen} onClose={handlePopupClose} />

        {/* Logout Dialog */}
        <Dialog open={isLogoutDialogOpen}>
          <LogoutConfirmation onClose={handleLogoutCancel} />
        </Dialog>
      </div>
      <div className={styles.search_placeholder} ref={containerRef}>
        <div className={styles.search_contant} >
          <div style={{display:'flex',background:"#FAF3E0",justifyContent:"center",alignItems:'center',borderRadius:"10px",width:"auto"}}>
            {showSuggestions && (
              <>
              <input
                type="text"
                className={styles.searchInput}
                style={{ color: '#7E7E7E',letterSpacing:"0.05rem" }} 
                placeholder="Search for products,category and more..."
                value={searchText}
                onChange={handleSearchChange}
             
              />
              </>
            )}
            
          </div>
          <div className={styles.smilerproduct}>
            {showSuggestions && renderSuggestions()}
          </div>
        </div>
             
        <div className={styles.dropdownContainer}>
            <div className={styles.customSelect}>
              <div className={styles.selectedOption}>
              <ReactCountryFlag countryCode={countries.getAlpha2Code(country, 'en')} svg /> {"  "} {country}
                <IoIosArrowDown style={{ marginLeft: "8px", fontSize: "16px" }} />
              </div>
              <ul className={styles.optionsList}>
  {currencies?.map((currency) => (
    <li
      key={currency._id}
      onClick={() => handleChange(currency)}
      className={`${styles.option} ${
        currency.currencyCode === selectedCurrency ? styles.selected : ""
      }`}
    >
      <div style={{ display: "flex", gap:"10px", width: "100%" , alignItems:"center" }}>
      {currency?.country && <ReactCountryFlag countryCode={countries.getAlpha2Code(currency?.country, 'en')} svg />}{currency?.country}
      </div>
    </li>
  ))}
</ul>

            </div>
         
     </div>
      </div>
    </>
  );
};

const MyProfileIcon = () => (
  <svg
    width="84"
    height="24"
    viewBox="0 0 94 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 21H13V15H21V21ZM11 21H3V11H11V21ZM21 13H13V3H21V13ZM11 9H3V3H11V9Z"
      fill="white"
    />
  </svg>
);

const OrderHistoryIcon = () => (
 
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 20V13H11L7.783 16.22C8.33247 16.7819 8.98837 17.2287 9.71241 17.5343C10.4364 17.8399 11.2141 17.9983 12 18C13.2393 17.9982 14.4475 17.6127 15.4589 16.8965C16.4702 16.1802 17.2349 15.1684 17.648 14H17.666C17.78 13.675 17.867 13.34 17.925 13H19.937C19.6934 14.9333 18.7527 16.7111 17.2913 18C15.83 19.2888 13.9485 20 12 20H11.99C10.9398 20.0032 9.89944 19.798 8.9291 19.3963C7.95876 18.9946 7.07772 18.4045 6.337 17.66L4 20ZM6.074 11H4.062C4.30548 9.06751 5.24564 7.29019 6.70616 6.00145C8.16667 4.7127 10.0472 4.00108 11.995 4.00004H12C13.0504 3.99671 14.0909 4.20183 15.0615 4.6035C16.032 5.00517 16.9132 5.59541 17.654 6.34004L20 4.00004V11H13L16.222 7.78004C15.672 7.21752 15.0153 6.77035 14.2903 6.46471C13.5654 6.15907 12.7867 6.0011 12 6.00004C10.7607 6.00187 9.55246 6.38738 8.54114 7.10361C7.52982 7.81985 6.76508 8.83166 6.352 10H6.334C6.219 10.325 6.132 10.66 6.075 11H6.074Z" fill="white"/>
</svg>

);
const WishlistIcon = () => (
  <svg
    width="84"
    height="24"
    viewBox="0 0 94 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9997 19.0538C-7.99987 7.99967 6.00011 -4.00033 11.9997 3.58772C18.0001 -4.00034 32.0001 7.99967 11.9997 19.0538Z"
      stroke="white"
      stroke-width="1.5"
    />
  </svg>
);

const AddressIcon = () => (
  
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.6603 14.4939C17.5224 13.5831 18.0986 12.4397 18.3179 11.2049C18.5371 9.97016 18.3897 8.69822 17.8939 7.54632C17.3981 6.39442 16.5756 5.41305 15.5281 4.72352C14.4807 4.03398 13.2541 3.6665 12 3.6665C10.7459 3.6665 9.51935 4.03398 8.47186 4.72352C7.42438 5.41305 6.60191 6.39442 6.10611 7.54632C5.61031 8.69822 5.46291 9.97016 5.68215 11.2049C5.90138 12.4397 6.47763 13.5831 7.33967 14.4939C7.83436 13.7045 8.52158 13.0538 9.33678 12.603C10.152 12.1521 11.0684 11.9158 12 11.9163C12.9316 11.9158 13.848 12.1521 14.6632 12.603C15.4784 13.0538 16.1656 13.7045 16.6603 14.4939ZM8.68625 15.8442L12 19.157L15.3138 15.8442C15.0166 15.2168 14.5474 14.6866 13.9608 14.3154C13.3742 13.9442 12.6942 13.7471 12 13.7471C11.3058 13.7471 10.6258 13.9442 10.0392 14.3154C9.45259 14.6866 8.98342 15.2168 8.68625 15.8442ZM12 21.7503L6.16634 15.9166C5.01256 14.7628 4.22684 13.2928 3.90852 11.6925C3.5902 10.0921 3.75358 8.43333 4.37801 6.92585C5.00243 5.41836 6.05986 4.12989 7.41656 3.22338C8.77326 2.31686 10.3683 1.83301 12 1.83301C13.6317 1.83301 15.2267 2.31686 16.5834 3.22338C17.9402 4.12989 18.9976 5.41836 19.622 6.92585C20.2464 8.43333 20.4098 10.0921 20.0915 11.6925C19.7732 13.2928 18.9874 14.7628 17.8337 15.9166L12 21.7503ZM12 9.16627C12.2431 9.16627 12.4763 9.06969 12.6482 8.89778C12.8201 8.72588 12.9167 8.49272 12.9167 8.2496C12.9167 8.00649 12.8201 7.77333 12.6482 7.60142C12.4763 7.42951 12.2431 7.33294 12 7.33294C11.7569 7.33294 11.5237 7.42951 11.3518 7.60142C11.1799 7.77333 11.0833 8.00649 11.0833 8.2496C11.0833 8.49272 11.1799 8.72588 11.3518 8.89778C11.5237 9.06969 11.7569 9.16627 12 9.16627ZM12 10.9996C11.2707 10.9996 10.5712 10.7099 10.0555 10.1941C9.53973 9.67842 9.25 8.97895 9.25 8.2496C9.25 7.52026 9.53973 6.82079 10.0555 6.30506C10.5712 5.78933 11.2707 5.4996 12 5.4996C12.7293 5.4996 13.4288 5.78933 13.9445 6.30506C14.4603 6.82079 14.75 7.52026 14.75 8.2496C14.75 8.97895 14.4603 9.67842 13.9445 10.1941C13.4288 10.7099 12.7293 10.9996 12 10.9996Z" fill="white"/>
</svg>

);
const PaymentCardsIcon = () => (
  <svg
    width="84"
    height="24"
    viewBox="0 0 94 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 20V13H11L7.783 16.22C8.33247 16.7819 8.98837 17.2287 9.71241 17.5343C10.4364 17.8399 11.2141 17.9983 12 18C13.2393 17.9982 14.4475 17.6127 15.4589 16.8965C16.4702 16.1802 17.2349 15.1684 17.648 14H17.666C17.78 13.675 17.867 13.34 17.925 13H19.937C19.6934 14.9333 18.7527 16.7111 17.2913 18C15.83 19.2888 13.9485 20 12 20H11.99C10.9398 20.0032 9.89944 19.798 8.9291 19.3963C7.95876 18.9946 7.07772 18.4045 6.337 17.66L4 20ZM6.074 11H4.062C4.30548 9.06751 5.24564 7.29019 6.70616 6.00145C8.16667 4.7127 10.0472 4.00108 11.995 4.00004H12C13.0504 3.99671 14.0909 4.20183 15.0615 4.6035C16.032 5.00517 16.9132 5.59541 17.654 6.34004L20 4.00004V11H13L16.222 7.78004C15.672 7.21752 15.0153 6.77035 14.2903 6.46471C13.5654 6.15907 12.7867 6.0011 12 6.00004C10.7607 6.00187 9.55246 6.38738 8.54114 7.10361C7.52982 7.81985 6.76508 8.83166 6.352 10H6.334C6.219 10.325 6.132 10.66 6.075 11H6.074Z"
      fill="#CCCCCC"
    />
  </svg>
);
const LogoutIcon = () => (
  <svg
    width="84"
    height="24"
    viewBox="0 0 94 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 21H10C9.46957 21 8.96086 20.7893 8.58579 20.4142C8.21071 20.0391 8 19.5304 8 19V15H10V19H19V5H10V9H8V5C8 4.46957 8.21071 3.96086 8.58579 3.58579C8.96086 3.21071 9.46957 3 10 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21ZM12 16V13H3V11H12V8L17 12L12 16Z"
      fill="white"
    />
  </svg>
);
const Shopping = () => {
  // State to track the color
  const [color, setColor] = useState("#CCCCCC");

  // Function to handle color change on click
  const handleClick = () => {
    // Toggle between two colors (or you can use any colors you like)
    setColor((prevColor) => (prevColor === "#CCCCCC" ? "#FF0000" : "#CCCCCC"));
  };

  return (
   
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 10H5L3 21H21L19 10H16M8 10V7C8 4.79086 9.79086 3 12 3V3C14.2091 3 16 4.79086 16 7V10M8 10H16M8 10V13M16 10V13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );
};

export default Header;
