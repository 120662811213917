import React, { useEffect, useState } from "react";
import ProfileBody from "../../dashcomponents/profilebody/ProfileBody";
import ProfileMenu from "../../dashcomponents/profilemenu/ProfileMenu";
import styles from "./orderdetails.module.css";
import ReviewPopup from "../../../smallcomponents/reviewpopup/ReviewPopup";
import RequestPopup from "../../../smallcomponents/requestpopup/RequestPopup";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllCustomerReviews,
  getOrderDetails,
  getInvoice
} from "../../../redux/reducers/orderSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Box, CircularProgress } from "@mui/material";
import ErrorPage from "../../../components/errorpage/ErrorPage";
import { Dot1 } from "../../../Svg";
import StarRatings from "react-star-ratings";
import { unwrapResult } from "@reduxjs/toolkit";

const OrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, singleOrder, orderReviews,invoice } = useSelector(
    (state) => state.order
  );


  const { id } = useParams();


  console.log("myavoo",singleOrder)

  const purchaseDate = singleOrder?.createdAt ? moment(singleOrder.createdAt) : null;
const exchangeDeadline = purchaseDate ? purchaseDate.add(7, "days") : null;
const isExchangeAvailable = exchangeDeadline ? moment().isBefore(exchangeDeadline) : false;



  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isRequestPopupVisible, setIsRequestPopupVisible] = useState(false);
  const [reviewProduct, setReviewProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(getOrderDetails(id));
    dispatch(getAllCustomerReviews(id));
  }, [dispatch, id ]);


  // Close ReviewPopup
  const handleClose = () => {
    setIsPopupVisible(false);
  };

  // Close RequestPopup
  const handleRequestClose = () => {
    setIsRequestPopupVisible(false);
  };

  const handleSignupClick = (data) => {
    setIsPopupVisible(true);
    setReviewProduct(data);
  };

  // Handle Exchange button click
  const handleExchangeClick = () => {
    setIsRequestPopupVisible(true);
  };



  const handleGenerateInvoice = async (orderId) => {
    try {
      setLoading(true);
      setError(null);

     const result = await dispatch(getInvoice(orderId)).unwrap()

     console.log('invoiceeeee data',result)
      
      if (result?.invoice_url) {
        setInvoiceUrl(result?.invoice_url);
   
        const link = document.createElement('a');
        link.href = result?.invoice_url;
        link.download = 'invoice.pdf'; 
        link.click();
      } else {
        setError('Invoice creation failed');
      }
    } catch (err) {
      console.error('Error generating invoice:', err);
      setError('Something went wrong, please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <ProfileMenu selected={"Order history"} />
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          width="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className={styles.myprofile}>
          <div className={styles.ordetailsContainer}>
            <div className={styles.ordetailsheader}>
              <div className={styles.ordetails_status}>
                <h2>Order Details</h2>
                <div className={styles.status}>
                  <span>
                    <Dot1 />
                  </span>
                  &nbsp;{moment(singleOrder?.createdAt).format("D MMMM, YYYY")}
                  &nbsp;
                  <span>
                    <Dot1 />
                  </span>
                  &nbsp;
                  {singleOrder?.order_items?.length} product &nbsp;
                  <span>
                    <Dot1 />
                  </span>
                  &nbsp;
                  <span className={styles.changestatus}>
                    {singleOrder?.status}
                  </span>
                </div>
              </div>
              <button
                className={styles.editButton}
                onClick={() => navigate("/Order history")}
              >
                Back to List
              </button>
            </div>
            <div className={styles.ordetailsbody}>
              <div className={styles.ordetailsaddress}>
                <div className={styles.address_container}>
                  <div className={styles.address_box}>
                    <div className={styles.h3}> BILLING ADDRESS</div>
                    <div className={styles.address_deets}>
                      <p className={styles.name1}>
                        {singleOrder?.billing_customer_name}
                      </p>
                      <p
                        className={styles.discription}
                      >{`${singleOrder?.billing_address}, ${singleOrder?.billing_city}, ${singleOrder?.billing_state}, ${singleOrder?.billing_country}`}</p>
                      <p className={styles.headi1}>EMAIL</p>{" "}
                      <p className={styles.numb1}>
                        {singleOrder?.billing_email}
                      </p>
                      <p className={styles.headi1}>PHONE</p>{" "}
                      <p className={styles.numb1}>
                        {singleOrder?.billing_phone}
                      </p>
                    </div>
                  </div>
                  <div className={styles.address_box}>
                    <div className={styles.h3}> SHIPPING ADDRESS </div>
                    <div className={styles.address_deets}>
                      <p className={styles.name1}>
                        {singleOrder?.billing_customer_name}
                      </p>
                      <p
                        className={styles.discription}
                      >{`${singleOrder?.billing_address}, ${singleOrder?.billing_city}, ${singleOrder?.billing_state}, ${singleOrder?.billing_country}`}</p>
                      <p className={styles.headi1}>EMAIL </p>{" "}
                      <p className={styles.numb1}>
                        {singleOrder?.billing_email}
                      </p>
                      <p className={styles.headi1}>PHONE</p>{" "}
                      <p className={styles.numb1}>
                        {singleOrder?.billing_phone}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.order_summary}>
                  <div className={styles.order_head}>
                    <div>
                      <h6 className={styles.heading1}> ORDER ID:</h6> <br />
                      <p className={styles.number2}>{singleOrder?.order_id}</p>
                    </div>
                    <div>
                      <h6 className={styles.heading1}>PAYMENT METHOD:</h6>
                      <br />
                      <p className={styles.number2}>
                        {singleOrder?.payment_method}
                      </p>
                    </div>
                  </div>
                  <div className={styles.order_deets}>
                 <div className={styles.namesr}>
                      <p className={styles.minemenu}>Subtotal:</p>
                      <p className={styles.number1}>
                        {" "}
                        {singleOrder?.currency === "USD" ? "$" : "₹"}
                        {(singleOrder?.sub_total?.toFixed(2))}
                      </p>
                    </div>
                    <div className={styles.namesr}>
                      <p>Discount:</p>
                      <p className={styles.number1}>
                        {" "}
                        {singleOrder?.currency === "USD" ? "$" : "₹"}
                        {(singleOrder?.total_discount?.toFixed())}
                      </p>
                    </div>
                    <div className={styles.namesr}>
                      <p>Shipping:</p>{" "}
                      <p className={styles.number1}>
                        {" "}
                        {singleOrder?.shippingMode}
                      </p>
                    </div>
                    <div className={styles.namesr}>
                      <p className={styles.price_total}>Total:</p>{" "}
                      <p className={styles.number1}>
                        {" "}
                        <span className={styles.total_price}>
                        {singleOrder?.currency === "USD" ? "$" : "₹"}
                          {(Number(singleOrder?.grandTotal).toFixed(2))}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.progress_container}>
                <div
                  className={`${styles.progress_step} ${
                    singleOrder?.createdAt ? styles.active : ""
                  }`}
                >
                  <div className={styles.circle}>✔</div>
                  <div className={styles.progressmobile}>
                    <p>Order received</p>
                    <small>
                      {moment(singleOrder?.createdAt).format(
                        "MMM D, YYYY h:mm A"
                      )}
                    </small>
                  </div>
                </div>

                <div
                  className={`${styles.progress_step} ${
                    singleOrder?.processingDate ? styles.active : ""
                  }`}
                >
                  <div className={styles.circle}>02</div>
                  <div className={styles.progressmobile}>
                    <p>Processing</p>
                    <small>
                      {singleOrder?.processingDate
                        ? moment(singleOrder.processingDate).format(
                            "MMM D, YYYY h:mm A"
                          )
                        : "Expecting Soon"}
                    </small>
                  </div>
                </div>

                <div
                  className={`${styles.progress_step} ${
                    singleOrder?.shippedDate ? styles.active : ""
                  }`}
                >
                  <div className={styles.circle}>03</div>
                  <div className={styles.progressmobile}>
                    <p>Shipped</p>
                    <small>
                      {singleOrder?.shippedDate
                        ? moment(singleOrder.shippedDate).format(
                            "MMM D, YYYY h:mm A"
                          )
                        : "Expecting Soon"}
                    </small>
                  </div>
                </div>

                <div
                  className={`${styles.progress_step} ${
                    singleOrder?.deliveredDate ? styles.active : ""
                  }`}
                >
                  <div className={styles.circle}>04</div>
                  <div className={styles.progressmobile}>
                    <p>Delivered</p>
                    <small>
                      {singleOrder?.deliveredDate
                        ? moment(singleOrder.deliveredDate).format(
                            "MMM D, YYYY h:mm A"
                          )
                        : "Expecting Soon"}
                    </small>
                  </div>
                </div>
              </div>

              <div className={styles.orderSummary}>
                <table className={styles.ordertable}>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {singleOrder?.order_items?.map((product, index) => (
                      <tr key={index}>
                        <td className={styles.productInfo}>
                          <div className={styles.productalign}>
                            <img
                              width={60}
                              height={60}
                              src={product?.productId?.featuredImage}
                              alt={product.name}
                              className={styles.productImage}
                            />
                            <div className={styles.pnamereview}>
                              <span>{product.name}</span>
                              {singleOrder?.status === "DELIVERED" ? (
  <>
    {orderReviews.some(
      (review) =>
        review.productId._id === product.productId._id 
    ) ? (
      // If the review exists, display the rating stars
      <div>
  <div className={styles.ratingStars}>
  {orderReviews.find((review) => review.productId._id === product.productId._id) ? (
    <StarRatings
      rating={
        orderReviews.find((review) => review.productId._id === product.productId._id)?.rating || 0
      }
      starRatedColor="gold"
      numberOfStars={5}
      starDimension="15px"
      starSpacing="1px"
      name="rating"
    />
  ) : (
    // Show "Write a Review" button if no review exists
    <button
      className={styles.reviewbtn}
      onClick={() => handleSignupClick(product)}
    >
      Write a review
    </button>
  )}
</div>

      </div>
    ) : (
      // If no review exists, show the "Write a Review" button
      <button
        className={styles.reviewbtn}
        onClick={() => handleSignupClick(product)}
      >
        Write a review
      </button>
    )}
  </>
) : null}


                            </div>
                          </div>
                        </td>
                        <td>
                        {singleOrder?.currency === "USD" ? "$" : "₹"}
                         {(((product?.selling_price / singleOrder?.currencyValue|| 0) / 1.18)  * (1 + (singleOrder?.tax / 100))).toFixed(2)}
                        </td>
                        <td>x {product?.units}</td>
                        <td>
                        {singleOrder?.currency === "USD" ? "$" : "₹"}
                        {((((product?.selling_price / singleOrder?.currencyValue|| 0)  / 1.18)  * (1 + (singleOrder?.tax / 100))) * product?.units).toFixed(2)}
                          </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className={styles.order_rejection}>
                  {singleOrder?.status == "EXCHANGE REJECTED" ? (
                    <div className={styles.rejection}>
                      <h3>Reason for Exchange rejection</h3>
                      <hr />
                      <p>{singleOrder?.exchange?.reasonForRejection}</p>
                    </div>
                  ) : null}

                  <div className={styles.orderbuttons}>
                    {singleOrder?.status === "DELIVERED" ? (
                      <>
                      {isExchangeAvailable && (
                      <button
                        className={styles.trackOrderBtn}
                        onClick={handleExchangeClick}
                      >
                        Exchange
                      </button>
                      )}
                        <button className={styles.invoiceBtn} onClick={() => handleGenerateInvoice(singleOrder?.order_id)}>Invoice</button> 
                        </>
                    ) : null}
                    {/* <button className={styles.trackOrderBtn}>Track order</button> */}
                    {/* <button className={styles.invoiceBtn}>Invoice</button> */}
                  </div>
                </div>
              </div>
              <div className={styles.mobile_order}>
                {" "}
                <span className={styles.st4}>Product</span>
                <div className={styles.mobile_orderSummary}>
                  <div className={styles.mobile_table}>
                    {singleOrder?.order_items?.map((product, index) => (
                      <div className={styles.moklist}>
                        <div>
                          <img
                            width={60}
                            height={60}
                            src={product?.productId?.featuredImage}
                            alt={product.name}
                            className={styles.productImage}
                          />
                        </div>
                        <div className={styles.menustart}>
                          <span className={styles.mobilename}>
                            {product.name}
                          </span>
                          <div className={styles.second_class}>
                            <div className={styles.size_mobile}>
                              Size:
                              <span className={styles.size_mobile1}>
                                {" "}
                                {product?.size}{" "}
                              </span>
                            </div>
                            <div className={styles.size_mobile}>
                              Qty:
                              <span className={styles.size_mobile1}>
                                {" "}
                                {product?.units}
                              </span>
                            </div>
                          </div>
                          <div className={styles.size_mobile}>
                            Price:
                            <span className={styles.size_mobile1}>
                              {" "}
                              {singleOrder?.currency === "USD" ? "$" : "₹"}
                              {(((product?.selling_price / singleOrder?.currencyValue|| 0) / 1.18)  * (1 + (singleOrder?.tax / 100))).toFixed(2)}

                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.orderbuttons}>
                  {singleOrder?.status === "DELIVERED" ? (
                    <>
                    <button
                      className={styles.trackOrderBtn}
                      onClick={handleExchangeClick}
                    >
                      Exchange
                    </button>
                     <button className={styles.invoiceBtn}>Invoice</button> 
                     </>
                  ) : null}
                  {/* <button className={styles.trackOrderBtn}>Track order</button>
                  <button className={styles.invoiceBtn}>Invoice</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Review Popup */}
      {isPopupVisible && (
        <ReviewPopup
          onClose={handleClose}
          orderId={singleOrder?._id}
          data={reviewProduct}
        />
      )}

      {/* Request Popup */}
      {isRequestPopupVisible && (
        <RequestPopup onClose={handleRequestClose} data={singleOrder} id={id} />
      )}
    </div>
  );
};

export default OrderDetails;
