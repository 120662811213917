import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./productpage.module.css";
import Slider from "react-slick";
import StarRatings from "react-star-ratings";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import {CircularProgress,Breadcrumbs,Typography} from "@mui/material";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import moment from "moment";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {
  BradeArrow,
  Card,
  Carticon,
  Color1,
  HomeNext,
  HomeNexts,
  HomePrev,
  Likewhite,
  Shifticon,
  Shourticon,
  Star,
  Star12,
  Trck,
  Troukicon,
} from "../../Svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAProductById,
  getSimilarProducts,
  addViewCount,
  getAllReviews,
  subscribeToOutofstockProducts,
  getPincodeValidation,
  setFilterValues,
  setFromSearch
} from "../../redux/reducers/productSlice";
import { addProductToCart } from "../../redux/reducers/cartSlice";
import chroma from "chroma-js";
import { useNavigate } from "react-router-dom";
import toast from "../../utils/toast";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  addToWishlist,
  deleteFromWishlist,
  getWishlist,
} from "../../redux/reducers/wishlistSlice";
import { getAllAddress } from "../../redux/reducers/addressSlice";
import { unwrapResult } from "@reduxjs/toolkit";




const Productpage = () => {


  const dispatch = useDispatch();
  const nav = useNavigate();
  
  const rate = parseFloat(localStorage.getItem("rate")) || 1;
  const tax = localStorage.getItem("tax")
  const currency = localStorage.getItem("currency") || "INR";
  const [img, setImg] = useState(null);
  const token = localStorage.getItem("misseymoppettoken");
  const [pinCode, setPinCode] = useState();
  const [isPincodeSubmitted, setIsPincodeSubmitted] = useState(false);
  const [hoveredPrev, setHoveredPrev] = useState(false);
  const [hoveredNext, setHoveredNext] = useState(false);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);



  console.log("availableColors",availableColors)
  console.log("availableSizes",availableSizes)

  const { id } = useParams();

  useEffect(() => {
    dispatch(getWishlist());
  
  }, [dispatch]);

  const handleProductClick = async (nameOrCategory) => {
    console.log("nameOrCategory:", nameOrCategory); 
    try {

      // dispatch(setFilterValues({searchInput:nameOrCategory,}))
      
      const resultAction = await dispatch(setFilterValues({ searchInput: nameOrCategory}));
      unwrapResult(resultAction); 
      dispatch(setFromSearch(true));
      nav('/category product');
      window.scrollTo(0,0)
      
      
    } catch (error) {
      console.error('Failed to fetch product:', error);
    }
  };

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          await Promise.all([
            dispatch(getAProductById(id)),
            dispatch(getAllReviews(id)),
            dispatch(getSimilarProducts(id)),
            dispatch(addViewCount(id)),
            dispatch(getAllAddress()),
            
          ]);
        } catch (error) {
          console.error("Error fetching product data:", error);
        }
      };

      fetchData();
    }
  }, [id, dispatch]);

  const MAX_COLORS = 5; // Target number of colors
  const MAIN_COLORS = [
    "Red",
    "Green",
    "Blue",
    "Yellow", 
    "Orange",  
    "Purple", 
    "Cyan",  
    "Pink",  
    "Gray",  
    "Black", 
  ];


const MAX_SIZES = 5;
const ALL_SIZES = [
  "0-3 months",
  "3-6 months",
  "6-9 months",
  "9-12 months",
  "1-2 years",
  "2-4 years",
  "4-6 years",
  "6-8 years",
  "8-10 years",
  "10-12 years",
]

  const generateFakeSizes = (realSizes, count) => {
    const availableMainSizes = ALL_SIZES.filter(
      (size) => !realSizes.includes(size.toLowerCase()) // Exclude real sizes
    );
    return availableMainSizes.slice(0, count); // Take the required number of fake sizes
  };

  const generateMainFakeColors = (realColors, count) => {
   
    const realColorsLower = realColors.map((color) => color.toLowerCase());
  
    const availableMainColors = MAIN_COLORS.filter(
      (color) => !realColorsLower.includes(color.toLowerCase()) 
    );
  
    return availableMainColors.slice(0, count); 
  };
  

  
  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,6}$/.test(value)) { // Matches up to 6 digits
      setPinCode(value);
    }
  };

  const { singleProduct, similarProducts, averageStar, reviews , loading, pinCodeData,loadingPIN } = useSelector(
    (state) => state.products
  );

  const { addressData } = useSelector((state) => state.address);

  const breadcrumbs = [
    <Typography key="1" sx={{ color: "black" }}>
      <a href="/" style={{ textDecoration: "none", color: "black" }}>
        Home
      </a>
    </Typography>,
    <Typography key="2" sx={{ color: "black" }}>
      <a
        href="/category product"
        style={{ textDecoration: "none", color: "black" }}
      >
        Category Product
      </a>
    </Typography>,
        <Typography key="2" sx={{ color: "black" }}>
        <a
          href="#"
          onClick={() => handleProductClick(singleProduct?.category?.name)}
          style={{ textDecoration: "none", color: "black" }}
        >
          {singleProduct?.category?.name}
        </a>
      </Typography>,      
         <Typography key="2" sx={{ color: "black" }}>
         <a
                href="#"
                onClick={() => handleProductClick(singleProduct?.subCategory?.name)}      
           style={{ textDecoration: "none", color: "black" }}
         >
           {singleProduct?.subCategory?.name}
         </a>
       </Typography>,
                <Typography key="2" sx={{ color: "black" }}>
                <a
             href="#"
             onClick={() => handleProductClick(singleProduct?.level3?.name)}      
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {singleProduct?.level3?.name}
                </a>
              </Typography>,
    <Typography key="3" sx={{ color: "rgb(200, 101, 80)" }}>
     {singleProduct?.name?.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())}
    </Typography>,
  ];
  
  useEffect(() => {
    
    if(addressData.length > 0){

    const defaultAddress = addressData?.find((address) => address.isDefault)

    if(defaultAddress){
      setPinCode(defaultAddress?.zip)
      dispatch(getPincodeValidation(pinCode))
    }
    else{
      setPinCode(addressData[0]?.zip)
      dispatch(getPincodeValidation(pinCode))
    }
  }
  },[addressData])

  const handlePincodeChange =() => {
   
    if (pinCode.length === 6) {
      setIsPincodeSubmitted(true); 
      dispatch(getPincodeValidation(pinCode))
    } else {
      toast.warning("Add Valid 6 Digit PIN")
      setIsPincodeSubmitted(false);
    }
  }


  const { wishlistData } = useSelector((state) => state.wishlist);

  const { cartData } = useSelector((state) => state.cart);

  const formattedDate = (date) => {
    const dateFromMongoDB = moment(date);

    // If the date is within the last hour, show "xx mins ago"
    if (moment().diff(dateFromMongoDB, "minutes") < 60) {
      return dateFromMongoDB.fromNow(); // e.g., "45 minutes ago"
    }

    // If the date is today, show "Today at hh:mm A"
    if (moment().isSame(dateFromMongoDB, "day")) {
      return `Today at ${dateFromMongoDB.format("hh:mm A")}`;
    }

    // If the date is yesterday, show "Yesterday at hh:mm A"
    if (moment().subtract(1, "days").isSame(dateFromMongoDB, "day")) {
      return `Yesterday at ${dateFromMongoDB.format("hh:mm A")}`;
    }

    // If the date is older, show "MM-DD-YYYY hh:mm A"
    return dateFromMongoDB.format("DD-MM-YYYY hh:mm A");
  };
  

  const sliderRef = useRef(null);
  const sliderRef2 = useRef(null);

  
  console.log("singleProduct", singleProduct);

  console.log("similar products", similarProducts);

  const sliderSettings = {
    dots: false,
    infinite: similarProducts.length > 1,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1038,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings = {
    dots: true,
    infinite:  true,
    autoplay: true,
    initialSlide: 0,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    centerPadding: "60px",
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "40px"
        },
      },
      {
        breakpoint: 395,
        settings: {
          centerPadding: "0px",
          centerMode: false,  
          
        }
      }
    ]
  };

  // console.log("Slider settings:", sliderSettings);

  const [isOpen, setIsOpen] = useState(true);
  const [isOpenr, setIsOpenR] = useState(true);
  const [isOpenf, setIsOpenF] = useState(true);
  const [count, setCount] = useState(1);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [currentImages, setCurrentImages] = useState([]);
  const [isActive, setIsActive] = useState(false);

  console.log("selected size ", selectedSize)
  console.log("selected color ", selectedColor)
  // console.log("currentImages",currentImages)



  const handleInputFocus = () => {
    setIsActive(true);
  };

  const handleInputBlur = () => {
    if (count === null) {
      setIsActive(false);
    }
  };


  // console.log("count", count);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const toggleDropdownR = () => {
    setIsOpenR(!isOpenr);
  };
  const toggleDropdownF = () => {
    setIsOpenF(!isOpenf);
  };

  const handleAddToCart = (item) => {
    try{
    const body = {
      productId: item?._id,
      quantity: 1,
      countInc: true,
      size: selectedSize ,
      color: selectedColor ,
    };

    dispatch(addProductToCart(body));
  }catch{
    console.error("failed to add to cart")
  }
  };

  const handleAddToWishList = (item) => {
    // console.log("itemmmmmmmmm", item);

    const body = {
      productId: item._id,
      quantity: 1,
      countInc: true,
      size: item?.variationInventory[0]?.size,
      color: item?.variationInventory[0]?.color,
    };

    const bodyToDelete = {
      item: item,
      size: item?.variationInventory[0]?.size,
      color: item?.variationInventory[0]?.color,
    };

    if (
      wishlistData?.some((wishlistItem) => wishlistItem?.item?._id === item._id)
    ) {
      dispatch(deleteFromWishlist(bodyToDelete));
    } else {
      dispatch(addToWishlist(body));
    }
  };

  const additionalInfo = [
    { icon: <Card />, text: "Secure Payment" },
    { icon: <Shourticon />, text: "Size & Fit" },
    { icon: <Troukicon />, text: "Free shipping" },
    { icon: <Shifticon />, text: " Exchange Option Available" },
  ];

  const validVariation = singleProduct?.variationInventory?.find(
    (variation) =>
      variation.color === selectedColor && variation.size === selectedSize
  );

  console.log("valid variation",validVariation)

  const isOutOfStock = validVariation?.quantity === 0;
  const isLowStock = validVariation?.quantity > 0 && validVariation?.quantity < 5;
  

  const addToCart = async(item) => {
    if (!token) {
      nav("/login");
      toast.warning("Please login");
      return;
    }
    if (validVariation && !isOutOfStock) {
      try{
      const body = {
        productId: item?._id,
        count: count || 1,
        countInc: true,
        size: selectedSize,
        color: selectedColor,
      };
      console.log("Adding to cart", count);
     
     const result = await dispatch(addProductToCart(body));

     await unwrapResult(result)

     toast.success("Cart Updated");

      setCount(1);
    }
    catch(err){
      console.log(err)
    }
    } else {
      // alert("Selected color and size combination is not available or out of stock.");
    }
  };

  useEffect(() => {
    if (singleProduct?.variationInventory) {
      // Filter sizes based on selected color
      const sizesForColor = singleProduct.variationInventory
        .filter((item) => item.color === selectedColor && item.quantity > 0)
        .map((item) => item.size);

      // Filter colors based on selected size
      const colorsForSize = singleProduct.variationInventory
        .filter((item) => item.size === selectedSize && item.quantity > 0)
        .map((item) => item.color);

      setAvailableSizes(sizesForColor);
      setAvailableColors(colorsForSize);
    }
  }, [selectedColor, selectedSize, singleProduct]);


  useEffect(() => {
    if (singleProduct?.variations?.length) {
      const initialColor = singleProduct?.variations.filter(
        (variation) => variation?.type?.name === "Color"
      )[0]?.value[0];
      const initialSize = singleProduct?.variations.filter(
        (variation) => variation?.type?.name === "Size"
      )[0]?.value[0];
      setSelectedColor(initialColor);
      setSelectedSize(initialSize);
    }
  }, [singleProduct]);

  useEffect(() => {
    if (
      singleProduct?.variationInventory?.length &&
      selectedColor &&
      selectedSize
    ) {
      if (validVariation) {
        setCurrentImages(validVariation.images || []);
        // You can also store other details of the matched variation, like price or availability
      } else {
        // If no matching variation is found, you can clear or show a warning
        setCurrentImages([]);
      }
    }
  }, [selectedColor, selectedSize, singleProduct]);

  useEffect(() => {
    if (sliderRef2?.current && currentImages?.length > 0) {
      console.log("Resetting to the first slide");
      setTimeout(() => {
        sliderRef2?.current.slickGoTo(0); // Ensure slider starts at the first image
        sliderRef2?.current.slickPause(); // Pause autoplay
        sliderRef2?.current.slickPlay();  // Restart autoplay
      }, 100); 
    }
  }, [currentImages, selectedColor, selectedSize, singleProduct]);

  const totalReviews = reviews?.ratingCounts?.reduce(
    (sum, item) => sum + item.count,
    0
  );

  const filteredProductDetails = singleProduct?.variations?.filter(
    ({ name }) => name !== "Size" && name !== "Color"
  );

  const defaultProductDetails = [
    { name: "Fabric" },
    { name: "Pattern" },
    { name: "Fit" },
    { name: "Neck" },
    { name: "Sleeve" },
    { name: "Style" },
  ];

  // Map over default labels and add values or "N/A" if not found
  const productDetails = defaultProductDetails?.map((defaultDetail) => {
    const matchingVariation = filteredProductDetails?.find(
      (variation) => variation?.name === defaultDetail?.name
    );
    return {
      name: defaultDetail?.name,
      value: matchingVariation ? matchingVariation?.value : "N/A",
    };
  });

  return (
    <>
    {loading ? (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: 20,
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
    <div className={styles.containes}>
<div className={styles.breadcrumbs}>
      <Breadcrumbs
        separator={ <BradeArrow />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      </div>
      <div className={styles.container}>
   
        <div className={styles.section1}>
       
        <div className={`${styles.slidercontant} slidercontant`}>
        <Slider key={currentImages.join(",")} {...settings} ref={sliderRef2}>
  {currentImages.map((image, index) => (
    <div key={index} className={styles.card1}>
      <div className={styles.card2}>
        <Zoom>
          <img
            src={image}
            onClick={() => setImg(image)}
            className={styles.mainImg}
            alt={`Item ${index + 1}`}
          />
        </Zoom>
      </div>
    </div>
  ))}
</Slider>
  </div>
          <div className={styles.navbutton1}>
          <div
  className={styles.prev}
  style={{
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    backgroundColor: "#f0f0f0", 
    borderRadius: "50%", 
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", 
    transition: "transform 0.2s ease, background-color 0.2s ease",
  }}
  onClick={() => sliderRef2.current?.slickPrev()}
  onMouseOver={(e) =>
    (e.currentTarget.style.backgroundColor = "#e0e0e0") // Darker background on hover
  }
  onMouseOut={(e) =>
    (e.currentTarget.style.backgroundColor = "#f0f0f0") // Reset background
  }
  onMouseDown={(e) =>
    (e.currentTarget.style.transform = "scale(0.9)") // Scale down on click
  }
  onMouseUp={(e) =>
    (e.currentTarget.style.transform = "scale(1)") // Reset scale on release
  }
>
  <span
    style={{
      display: "inline-block",
      border: "solid #333", // Dark arrow color
      borderWidth: "0 3px 3px 0",
      padding: "5px",
      transform: "rotate(135deg)", // Left arrow
    }}
  ></span>
</div>

            <div
  className={styles.next}
  style={{
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    backgroundColor: "#f0f0f0", // Light grey background
    borderRadius: "50%", // Circular button
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for elevation
    transition: "transform 0.2s ease, background-color 0.2s ease",
  }}
  onClick={() => sliderRef2.current?.slickNext()}
  onMouseOver={(e) =>
    (e.currentTarget.style.backgroundColor = "#e0e0e0") // Darker background on hover
  }
  onMouseOut={(e) =>
    (e.currentTarget.style.backgroundColor = "#f0f0f0") // Reset background on mouse out
  }
  onMouseDown={(e) =>
    (e.currentTarget.style.transform = "scale(0.9)") // Scale down on click
  }
  onMouseUp={(e) =>
    (e.currentTarget.style.transform = "scale(1)") 
  }
>
  <span
    style={{
      zIndex:10 ,
      display: "inline-block",
      border: "solid #333", // Dark arrow color
      borderWidth: "0 3px 3px 0",
      padding: "5px",
      transform: "rotate(-45deg)", // Right arrow
    }}
  ></span>
</div>

          </div>
        </div>

        <div className={styles.section2}>
          <div>
            <h5 className={styles.heading}> {singleProduct?.name?.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())}</h5>

            {/* Rating and Price Section */}
            <div className={styles.ratingPrice}>
              <div className={styles.rating}>
                <span className={styles.star} style={{ fontSize: "34px" }}>
                  <StarRatings
                    rating={Number(averageStar) || 0}
                    starRatedColor="gold"
                    numberOfStars={5}
                    starDimension="25px"
                    starSpacing="5px"
                    name="rating"
                  />
                </span>
                <span className={styles.num}>{averageStar}</span>
              </div>
              <div className={styles.price}>
                <span className={styles.originalPrice}>
                  {currency === "USD" ? "$" : "₹"}
                  {currency === "USD"
                 
                    ? (((singleProduct?.basePrice * rate) / 1.18) * (1 + (tax / 100))).toFixed(2)
                    : singleProduct?.basePrice}
                </span>
                <span className={styles.discountedPrice}>
                  {currency === "USD" ? "$" : "₹"}
                  {currency === "USD"
                    ? ((((singleProduct?.salePrice + validVariation?.additionalPrice) * rate )  / 1.18) * (1 + (tax / 100))).toFixed(2)
                    : singleProduct?.salePrice + validVariation?.additionalPrice}
                </span>
              </div>
            </div>
            {isLowStock && (
              <div className={styles.lowStock}>
                Only {validVariation.quantity} left in stock!
              </div>
            )}
            {isOutOfStock && (
              <div className={styles.outOfStock}>Out of stock</div>
            )}
          </div>
          <div>
      {/* Select Size */}
      <p className={styles.ptext}>Select Size</p>
      <div className={styles.sizeOptions}>
  {(() => {
    const realSizes = singleProduct?.variations
      ?.filter((variation) => variation?.type?.name === "Size")[0]
      ?.value || [];

    const fakeSizesNeeded = Math.max(0, MAX_SIZES - realSizes.length);
    const fakeSizes = generateFakeSizes(realSizes, fakeSizesNeeded);
    const allSizes = [...realSizes, ...fakeSizes];

    return allSizes.slice(0, MAX_SIZES).map((size, index) => {
      const isAvailable = availableSizes.includes(size);

      return (
        <button
          key={index}
          className={`${styles.sizeButton} ${
            selectedSize === size ? styles.activeSize : ""
          } ${!isAvailable ? styles.unavailableSize : ""}`}
          onClick={() => isAvailable && setSelectedSize(size)}
          disabled={!isAvailable} // Disable if not available
        >
          {size}
          {!isAvailable && <div className={styles.crossLineSize} />}
        </button>
      );
    });
  })()}
</div>

      {/* Select Color */}
      <p className={styles.ptext}>Colours Available</p>
      <div className={styles.colorsAvailable}>
  {(() => {
    const realColors = singleProduct?.variations
      ?.filter((variation) => variation?.type?.name === "Color")[0]
      ?.value || [];

    const fakeColorsNeeded = Math.max(0, MAX_COLORS - realColors.length);
    const fakeColors = generateMainFakeColors(realColors, fakeColorsNeeded);
    const allColors = [...realColors, ...fakeColors];

    return allColors.slice(0, MAX_COLORS).map((color, index) => {
      const colorHex = chroma.valid(color) ? chroma(color).hex() : "#FFF";
      const isAvailable = availableColors.includes(color);

      return (
        <button
          key={index}
          className={`${styles.colorCircle} ${
            selectedColor === color ? styles.activeColor : ""
          } ${!isAvailable ? styles.unavailable : ""}`}
          style={{
            background: colorHex,
            border: selectedColor === color ? "1.4px solid rgba(63, 70, 70, 1)" : "none",
            opacity: isAvailable ? 1 : 0.5,
          }}
          onClick={() => isAvailable && setSelectedColor(color)}
          disabled={!isAvailable}
        >
          {!isAvailable && <div className={styles.crossLineColor} />}
        </button>
      )
    })
  })()}
</div>
    </div>



          <div className={styles.cartbutton}>
          {isOutOfStock ? null : (
  <>
    <p className={styles.g_text}>QTY</p>
    <div className={styles.cartInputContainer}>
      <button
        className={styles.counterButton}
        onClick={() => setCount((prev) => Math.max(1, prev - 1))}
      >
        -
      </button>
      <input
        type="number"
        placeholder="1"
        className={styles.cartInput}
        value={isActive ? "" : count}
        onChange={(e) => setCount(Math.max(1, Number(e.target.value)))}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        min={1}
        disabled
      />
      <button
        className={styles.counterButton}
        onClick={() => setCount((prev) => prev + 1)}
      >
        +
      </button>
    </div>
  </>
)}

            {isOutOfStock ? (
              <button
                className={styles.addToCart}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    subscribeToOutofstockProducts({
                      productId: singleProduct?._id,
                    })
                  );
                }}
              >
                <span className={styles.cartIcon}>{/* <Carticon /> */}</span>
                Notify me
              </button>
            ) : (
              <button
           
                className={styles.addToCart}
                // style={{
                //   cursor: !pinCodeData?.data ? "not-allowed" : "pointer",
                //   opacity:!pinCodeData?.data ? .2 : 1,
                // }}
                onClick={() => addToCart(singleProduct)}
                disabled={isOutOfStock }
                // disabled={!pinCodeData.data}
              
              >
                <span className={styles.cartIcon}>
                  <Carticon />
                </span>{" "}
                Add to cart
              </button>
            )}
          </div>

          {/* Additional Information */}
          <div className={styles.additionalInfo}>
            {additionalInfo.map((info, index) => (
              <div key={index} className={styles.infoItem}>
                <span className={styles.infoIcon}>{info.icon}</span>
                <p className={styles.Ftext}>{info.text}</p>
              </div>
            ))}
          </div>
          <div className={styles.container45}>
          <div className={styles.contant}>
          <div className={styles.contant45}>
          <i className={styles.deliveryIcon}><Trck/></i>
          </div>
          <div className={styles.contant46}>
  <div className={styles.pinCodeHeader}>
 
    <span className={styles.enter}>Enter your Pin code</span>
  </div>
  <div className={styles.pinCodeInput}>
      <input
        type="number"
        value={pinCode}
        maxLength={6}
        placeholder="Enter your Pincode"
        onChange={handleChange}
      />
      <button className={styles.changeBtn} onClick={handlePincodeChange}>CHANGE</button>
    </div>
    <div>
    {loadingPIN ? (
      <p className={styles.loadingStatus}>Checking availability...</p>
    ) : !pinCodeData.data && !pinCode ? (
      <p className={styles.deliveryError}>Please provide pincode for checking delivery</p>
    ) : pinCodeData.data ? (
      <>
        <p className={styles.availabilityStatus} style={{ color: "green" }}>
          Available for selected pincode
        </p>
        <p className={styles.availabilityStatus}>
          Estimated Delivery By: {pinCodeData?.data?.etd}
        </p>
      </>
    ) : (
      isPincodeSubmitted && ( // Only show this if pincode was submitted
        <p className={styles.deliveryError}>
          Can't be delivered to selected pincode
        </p>
      )
    )}

  </div>
  </div>
  </div>
</div>
          <div style={{ width: "100%" }}>
            <button
              onClick={toggleDropdown}
              className={styles.productDescriptionButton}
            >
              <div className={styles.productDescriptionTitle}>
                <div className={styles.ucontant}>
                  <Color1 />{" "}
                  <h5 className={styles.text_f}>Product Description</h5>
                </div>
                <div>
                  {isOpen ? (
                    <FaChevronUp className={styles.icon} />
                  ) : (
                    <FaChevronDown className={styles.icon} />
                  )}
                </div>
              </div>
            </button>

            {isOpen && (
              <div
                className={styles.dropdownContent}
                dangerouslySetInnerHTML={{ __html: singleProduct?.description }}
              ></div>
            )}

            {/* Array format for product description */}
            {/* <div className={styles.productDescription}>
              {productDescriptionArray.map((description, index) => (
                <p key={index}>{description}</p>
              ))}
            </div> */}

            {/* Grid layout for product details */}
            <div className={styles.descriptionGrid}>
              {productDetails?.map((item, index) => (
                <div
                  key={index}
                  className={styles[`descriptionItem${index + 1}`]}
                >
                  <p className={styles.label}>{item?.name || "N/A"} </p>
                  <p className={styles.value}>{item?.value || "N/A"} </p>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.newBornSection}>
           
          </div>
          <button
            onClick={toggleDropdownF}
            className={styles.productDescriptionButton}
          >
            <div className={styles.productDescriptionTitle}>
              <div className={styles.ucontant}>
                <Color1 /> <h5 className={styles.text_f}> Fabric Details</h5>
              </div>
              <div>
                {isOpenf ? (
                  <FaChevronUp className={styles.icon} />
                ) : (
                  <FaChevronDown className={styles.icon} />
                )}
              </div>
            </div> 
          </button>

          {isOpenf && (
            <div className={styles.dropdownContent}  dangerouslySetInnerHTML={{ __html: singleProduct?.fabricDetails }}>
   
            </div>
          )}
          <button
            onClick={toggleDropdownR}
            className={styles.productDescriptionButton}
          >
            <div className={styles.productDescriptionTitle}>
              <div className={styles.ucontant}>
                <Color1 /> <h5 className={styles.text_f}> Reviews</h5>
              </div>
              <div>
                {isOpenr ? (
                  <FaChevronUp className={styles.icon} />
                ) : (
                  <FaChevronDown className={styles.icon} />
                )}
              </div>
            </div>
          </button>

          {isOpenr && (
            <div className={styles.dropdownContent}>
              {/* Your dropdown content goes here */}
              <div className={styles.container44}>
                <div className={styles.ratingSummary}>
                  <div className={styles.ratingBreakdown}>
                    {[5, 4, 3, 2, 1].map((rating, index) => {
                      const ratingCount =
                        reviews?.ratingCounts?.find(
                          (item) => item?._id === rating
                        )?.count || 0;
                      const widthPercentage =
                        totalReviews > 0
                          ? (ratingCount / totalReviews) * 100
                          : 0;

                      return (
                        <div key={index} className={styles.ratingRow}>
                          <span className={styles.star_1}>
                            {rating} <Star12 />
                          </span>
                          <div className={styles.ratingBar}>
                            <div
                              className={styles.filledBar}
                              style={{ width: `${widthPercentage}%` }}
                            ></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className={styles.averageRating}>
                    <h2 className={styles.ratenum}>{averageStar} </h2>
                    <div className={styles.stars}>
                      <span className={styles.star_1}>
                        <StarRatings
                          rating={averageStar || 0}
                          starRatedColor="gold"
                          numberOfStars={5}
                          starDimension="15px"
                          starSpacing="1px"
                          name="rating"
                        />
                      </span>
                    </div>
                    <p>{reviews?.reviews?.length} Reviews</p>
                  </div>
                </div>

                <div className={styles.reviews}>
                  {reviews?.reviews?.map((review, index) => (
                    <div key={index} className={styles.review}>
                      <div className={styles.reviewHeader}>
                        <img
                          src={`https://www.svgrepo.com/show/486506/user-profile-filled.svg`}
                          alt={review?.name}
                          loading="lazy" 
                          className={styles.avatar}
                        />
                        <div className={styles.reviewInfo}>
                          <h3>{review?.userName}</h3>
                          <p>
                            <StarRatings
                              rating={review?.rating || 0}
                              starRatedColor="gold"
                              numberOfStars={5}
                              starDimension="15px"
                              starSpacing="1px"
                              name="rating"
                            />
                            <span className={styles.time}>
                              {formattedDate(review.createdAt)}
                            </span>
                          </p>
                        </div>
                        <div className={styles.moreOptions}>⋮</div>
                      </div>
                      <p className={styles.reviewText}>{review.comment}</p>
                      <div>
                        {review?.images?.map((image, index) => (
                          <img
                            key={index}
                            loading="lazy" 
                            src={image}
                            width={50}
                            alt={`Review Image ${index + 1}`}
                            style={{ marginRight: "2%" }}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* similerproduct slider */}
{similarProducts.length > 0 ? (
      <div className={styles.similerproduct}>
        <div className={styles.similer_nav}>
          <div className={styles.nav_sim}>
            <h4 className={styles.sim_heading}>Similar Products</h4>
            <div className={styles.navbutton}>
            <div
      style={{
        cursor: "pointer",
        borderRadius:"20px",
        transition: "transform 0.3s ease, background-color 0.3s ease",
        transform: hoveredPrev ? "scale(1.1)" : "scale(1)",
        backgroundColor: hoveredPrev ? "rgba(200, 200, 200, 0.2)" : "transparent",
      }}
      onClick={() => sliderRef.current?.slickPrev()}
      onMouseEnter={() => setHoveredPrev(true)}
      onMouseLeave={() => setHoveredPrev(false)}
    >
      <HomePrev />
    </div>
    <div
      style={{
        cursor: "pointer",
        borderRadius:"20px",
        transition: "transform 0.3s ease, background-color 0.3s ease",
        transform: hoveredNext ? "scale(1.1)" : "scale(1)",
        backgroundColor: hoveredNext ? "rgba(200, 200, 200, 0.2)" : "transparent",
      }}
      onClick={() => sliderRef.current?.slickNext ()}
      onMouseEnter={() => setHoveredNext(true)}
      onMouseLeave={() => setHoveredNext(false)}
    >
                <HomeNext />
              </div>
            </div>
          </div>
        </div>
        <Slider {...sliderSettings} ref={sliderRef}>
          {similarProducts?.map((product) => (
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to={`/Category Product/Product details/${product._id}`}
              style={{ textDecoration: "none" }}
              key={product._id}
            >
              <div className={styles.slider1}>
                <div className={styles.sec}>
                  <div className={styles.sec01}>
                    <div className={styles.maincontainer}>
                      <img
                        loading="lazy" 
                        src={product.featuredImage}
                        alt={product.name}
                        className={styles.SlideImage}
                      />
                      {product.sale && (
                        <div style={{ position: "absolute", top: 0 }}>
                          <p className={styles.sale}>SALE</p>
                        </div>
                      )}
                      <div className={styles.content}>
                        <div className={styles.right}>
                        <span className={styles.name}>{product?.name?.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())}</span>

                          {/* <span className={styles.name}>{product.name}</span> */}
                          <div className={styles.stars}>
                            <StarRatings
                              rating={product?.averageRating || 0}
                              starRatedColor="gold"
                              numberOfStars={5}
                              starDimension="15px"
                              starSpacing="1px"
                              name="rating"
                            />
                          </div>
                          {product?.stockQuantity > 0 ? (
                            <div className={styles.price}>
                              <h5 className={styles.discoutprice}>
                                {currency === "USD" ? "$" : "₹"}
                                {(currency === "USD"
                                  ? ((product.salePrice * rate) / 1.18) * (1 + (tax / 100))
                                  : product.salePrice
                                ).toFixed(2)}
                              </h5>
                              <h5 className={styles.beforeprice}>
                                {currency === "USD" ? "$" : "₹"}
                                {(currency === "USD"
                                  ? ((product.basePrice * rate) / 1.18) * (1 + (tax / 100))
                                  : product.basePrice
                                ).toFixed(2)}
                              </h5>
                            </div>
                          ) : (
                            <div
                              style={{
                                color: "red",
                                marginBottom: "8%",
                                fontSize: "14px",
                              }}
                            >
                              Out of stock
                            </div>
                          )}
                        </div>
                        <div
                          className={styles.right}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <div
                            className={styles.Like}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (token) {
                                handleAddToWishList(product);
                              } else {
                                nav("/login");
                                toast.warning("Please login");
                              }
                            }}
                          >
                            {wishlistData.some(
                              (wishlistItem) =>
                                wishlistItem?.item?._id === product._id
                            ) ? (
                              <FavoriteIcon
                                style={{
                                  width: "25px",
                                  color: "red",
                                  border: "none",
                                }}
                              />
                            ) : (
                              <FavoriteBorderIcon
                                sx={{
                                  stroke: "#ffffff",
                                  strokeWidth: 1,
                                  width: "25px",
                                  color: "black",
                                }}
                              />
                            )}
                          </div>
                          {product?.stockQuantity < 1  ? (
                            <div
                              className={styles.AddCart}
                              style={{marginBottom:"-10px"}}
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                  subscribeToOutofstockProducts(product?._id)
                                );
                              }}
                            >
                              + Notify me
                            </div>
                          ) : (
                            <>
                              {cartData?.some(
                                (cartItem) =>
                                  cartItem?.item?._id === product._id
                              ) ? (
                                <button
                                  className={styles.AddCart}
                                  disabled
                                  style={{
                                    background: "none",
                                    border: "none",
                                    cursor: "not-allowed",
                                    color: "green",
                                  }}
                                >
                                  Added to Cart
                                </button>
                              ) : (
                                <>
                                  <Link to="/mycart">
                                    <button
                                      className={styles.AddCart}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (token) {
                                          handleAddToCart(product);
                                        } else {
                                          nav("/login");
                                          toast.warning("Please Login");
                                        }
                                      }}
                                      style={{
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                      
                                    >
                                      + Add to Cart
                                    </button>
                                  </Link>
                                  {product?.stockQuantity <= 5 ? (
                                    <div
                                      style={{
                                        color: "orange",
                                        fontWeight: 400,
                                        fontSize: 12,
                                        textDecoration: "none",
                                      }}
                                    >
                                      Only {product?.stockQuantity} left in
                                      stock
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </Slider>
      </div>
      ) : null}
    </div>
    )}
   </>
  );
};

export default Productpage;
