import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/Api";
import toast from "../../utils/toast";

const initialState = {
  allProducts: [],
  productData: [],
  pagination: {},
  fromSearch: false,
  singleProduct: null,
  averageStar: null,
  similarProducts: [],
  reviews: [],
  // filterOptions: {},
  loading: false,
  loadingPIN: false,
  filterLoading: false,
  error: null,
  isMobile: null,
  pinCodeData: {},
  filterValues: {
    searchInput: "",
    minPrice: 0,
    maxPrice: 100000,
    selectedCategory: "",
    selectedColor: "",
    selectedSize: "",
    page: 1,
    sortBy:""
  },
};

export const getPincodeValidation = createAsyncThunk(
  "getPincodeValidation",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setData({
          name: "loadingPIN",
          value: true,
        })
      );
      dispatch(
        setData({
          name: "error",
          value: null,
        })
      );

      const { data, status } = await api.getPincodeValidation(body);

      if (status === 200) {
        console.log("dataa,d", data.data.data);

        dispatch(
          setData({
            name: "loadingPIN",
            value: false,
          })
        );

        dispatch(setPinCode(data.data));
      }
    } catch (error) {
      dispatch(
        setData({
          name: "loadingPIN",
          value: false,
        })
      );
      dispatch(
        setData({
          name: "error",
          value:
            error.response.data.message ||
            "'Something went wrong. Please try again later.'",
        })
      );

      dispatch(setPinCode({}));
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const getAllProducts = createAsyncThunk(
  "getAllProducts",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setData({
          name: "loading",
          value: true,
        })
      );
      dispatch(
        setData({
          name: "error",
          value: null,
        })
      );

      const { data, status } = await api.fetchProducts();

      if (status === 200) {
        console.log("dataa,d", data.data.data);
        dispatch(
          setData({
            name: "loading",
            value: false,
          })
        );
        dispatch(setAllProducts(data?.data?.data));
        dispatch(setPagination(data?.data?.pagination));
      }
    } catch (error) {
      dispatch(
        setData({
          name: "loading",
          value: false,
        })
      );
      dispatch(
        setData({
          name: "error",
          value:
            error.response.data.message ||
            "'Something went wrong. Please try again later.'",
        })
      );
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const getAProductById = createAsyncThunk(
  "getAProductById",
  async (productId, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setData({
          name: "loading",
          value: true,
        })
      );
      dispatch(
        setData({
          name: "error",
          value: null,
        })
      );
      const { data, status } = await api.getProductById(productId);

      console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaafd", data);
      if (status === 200) {
        dispatch(
          setData({
            name: "loading",
            value: false,
          })
        );

        dispatch(setSingleProduct(data?.data?.data?.product));
        dispatch(setAverageStar(data?.data?.data?.averageRating));
      }
    } catch (error) {
      dispatch(
        setData({
          name: "loading",
          value: false,
        })
      );
      dispatch(
        setData({
          name: "error",
          value:
            error.response.data.message ||
            "'Something went wrong. Please try again later.'",
        })
      );

      toast.error(error.response.data.message);
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const getSimilarProducts = createAsyncThunk(
  "getSimilarProducts",
  async (productId, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await api.getSimilarProducts(productId);

      if (status === 200) {
        console.log("data", data);

        dispatch(setSimilarProducts(data?.data?.data));
      }
    } catch (error) {
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const searchOrFilterProduct = createAsyncThunk(
  "searchOrFilterProduct",
  async (filters, { rejectWithValue, dispatch, getState }) => {
    const {pagination} = getState().products
    try {


      dispatch(
        setData({
          name: "loading",
          value: true,
        })
      );
      dispatch(
        setData({
          name: "error",
          value: null,
        })
      );


      const queryParams = new URLSearchParams(filters).toString();

      const { data, status } = await api.searchOrFilterProduct(queryParams);

      if (status === 200) {

        if (filters.isMobile === true) {
          dispatch(setIsMobile(true));
        }
   

        dispatch(
          setData({
            name: "loading",
            value: false,
          })
        );


        dispatch(setProducts(data?.data?.products));

      }
      dispatch(setPagination(data?.data?.pagination));

    } catch (error) {

      dispatch(
        setData({
          name: "loading",
          value: false,
        })
      );
      dispatch(
        setData({
          name: "error",
          value:
            error.response.data.message ||
            "'Something went wrong. Please try again later.'",
        })
      );
      dispatch(setProducts([]));
      rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
      if(error.response.data.message == "No Products Found") {
        dispatch(setPagination({...pagination, currentPage: 1}))
        return null;
      }
        }
  }
);

export const addViewCount = createAsyncThunk(
  "addViewCount",
  async (productId, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await api.addViewCount(productId);

      if (status === 200) {
        console.log("data", data);
        return;
      }
    } catch (error) {
      console.log("filterError", error);
      dispatch(setProducts([]));
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const sortProducts = createAsyncThunk(
  "sortProducts",
  async (soryBy, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setData({
          name: "loading",
          value: true,
        })
      );
      dispatch(
        setData({
          name: "error",
          value: null,
        })
      );
      const { data, status } = await api.sortProducts(soryBy);

      console.log(
        "sort dataaaaaaaaaaaaaaaaaaaaaaaaaa",
        data?.data?.data?.products
      );

      if (status === 200) {
        dispatch(
          setData({
            name: "loading",
            value: false,
          })
        );

        dispatch(setProducts(data?.data?.data?.products));
        dispatch(setPagination(data?.data?.data?.pagination));
      }
    } catch (error) {
      dispatch(
        setData({
          name: "loading",
          value: false,
        })
      );
      dispatch(
        setData({
          name: "error",
          value:
            error.response.data.message ||
            "'Something went wrong. Please try again later.'",
        })
      );

      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const getAllReviews = createAsyncThunk(
  "getAllReviews",
  async (productId, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await api.getAllReviews(productId);

      if (status === 200) {
        console.log("data", data);
        dispatch(setReviews(data?.data));
        return;
      }
    } catch (error) {
      console.log("filterError", error);

      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);


export const searchData = createAsyncThunk(
  "searchData",
  async (query, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await api.searchData(
        query
      );

      if (status === 200) {
        return data.data
      }
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);


export const subscribeToOutofstockProducts = createAsyncThunk(
  "subscribeToOutofstockProducts",
  async (productId, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await api.subscribeToOutofstockProducts(
        productId
      );

      if (status === 200) {
        console.log("data", data);
        toast.success("We Will Notify You When Product Is Back Online");
        return;
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Please Login");
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const getFilterCounts = createAsyncThunk(
  "getFilterCounts",
  async (filters, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setData({
          name: "filterLoading",
          value: true,
        })
      );
      dispatch(
        setData({
          name: "error",
          value: null,
        })
      );

      const { data, status } = await api.getFilterCounts();

      if (status === 200) {
        dispatch(
          setData({
            name: "filterLoading",
            value: false,
          })
        );

        console.log("data", data);
      }
      dispatch(setFilters(data?.data));
    } catch (error) {
      dispatch(
        setData({
          name: "filterLoading",
          value: false,
        })
      );
      dispatch(
        setData({
          name: "error",
          value:
            error.response.data.message ||
            "'Something went wrong. Please try again later.'",
        })
      );
      console.log("filterError", error);
      // dispatch(setProducts([]));
      return rejectWithValue(
        error.response.data.message ||
          "'Something went wrong. Please try again later.'"
      );
    }
  }
);

export const getAllShippingCountryWithoutPagination = createAsyncThunk('getAllShippingCountryWithoutPagination', async (body, { rejectWithValue, dispatch }) => {
  try {
      const { data, status } = await api.getAllShippingCountryWithoutPagination();

      if (status === 200) {

      }
      return data.data

  } catch (err) {
      toast.error(err.response.data.message);
      return rejectWithValue(err.response.data.message || "'Something went wrong. Please try again later.'")
  }
}
)

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.productData = action.payload;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setFromSearch: (state, action) => {
      state.fromSearch = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSingleProduct: (state, action) => {
      state.singleProduct = action.payload;
    },
    setAverageStar: (state, action) => {
      state.averageStar = action.payload;
    },
    setSimilarProducts: (state, action) => {
      state.similarProducts = action.payload;
    },
    setReviews: (state, action) => {
      state.reviews = action.payload;
    },
    setFilters: (state, action) => {
      state.filterOptions = action.payload;
    },
    setData: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    setAllProducts: (state, action) => {
      state.allProducts = action.payload;
    },
    setFilterValues: (state, action) => {
      state.filterValues = { ...state.filterValues, ...action.payload }
  },
  resetAndSetFilterValues: (state, action) => {
    state.filterValues = {
      ...initialState.filterValues, // Reset filterValues to its initial state
      ...action.payload, // Override specific fields in filterValues
    };
  },
    setPinCode: (state, action) => {
      state.pinCodeData = action.payload;
    },
  },
});

export const {
  setProducts,
  setSingleProduct,
  setData,
  setSimilarProducts,
  setFilters,
  setAverageStar,
  setPagination,
  setIsMobile,
  setReviews,
  setAllProducts,
  setFromSearch,
  setFilterValues,
  resetAndSetFilterValues,
  setPinCode,
} = productSlice.actions;
export default productSlice.reducer;
