import {createAsyncThunk , createSlice} from "@reduxjs/toolkit"
import api from "../../utils/Api";
import toast from "../../utils/toast";






const initialState = {
    user: null,
    loading: false,
    error: null,
    success: false,
    data:null
}

export const signUpWithPhone = createAsyncThunk("signUpWithPhone" , async (body , {rejectWithValue , dispatch}) => {
    try {
      
        const { data, status } = await api.signUpWithPhone(body);


        if (status === 200) {
            dispatch(setUser(data.data));
            return data.data; }
        
    } catch (error) {
        console.log("errr---------", error)
        toast.error(error.response.data.message)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})

export const verifySignUpWithOtp = createAsyncThunk("verifySignUpWithOtp" , async (body , {rejectWithValue , dispatch}) => {
    try {
        const { data, status } = await api.verifySignUpWithOtp(body);

        if (status === 200) {
            localStorage.setItem("misseymoppettoken",data?.data?.token)
            dispatch(setUser(data.data));
            toast.success(data.data.message)
        }
        
    } catch (error) {
        console.log("errr---------", error)
        toast.error(error.response.data.message)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})


export const resendOtpForSignUp = createAsyncThunk("resendOtpForSignUp" , async (body , {rejectWithValue , dispatch}) => {
    try {
      
        const { data, status } = await api.resendOtpForSignUp(body);
  

        console.log("daaaata",data)

        if (status === 200) {
            toast.success("otp send successfully")
            return data.data; }
        
    } catch (error) {
        toast.error(error.response.data.message)
        console.log("errr---------", error)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})

export const loginWithPhone = createAsyncThunk("loginWithPhone" , async (body , {rejectWithValue , dispatch}) => {
    try {
      
        const { data, status } = await api.loginWithPhone(body);
  

        console.log("daaaata",data)

        if (status === 200) {
            dispatch(setUser(data.data));
            return data.data; }
        
    } catch (error) {
        toast.error(error.response.data.message)
        console.log("errr---------", error)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})

export const verifyLoginWithOtp = createAsyncThunk("verifyLoginWithOtp" , async (body , {rejectWithValue , dispatch}) => {
    try {
        const { data, status } = await api.verifyLoginWithPhone(body);
  

        console.log("daaaata",data)

        if (status === 200) {
            localStorage.setItem("misseymoppettoken",data?.data?.token)
            dispatch(setUser(data.data));
            toast.success(data.data.message)
        }
        
    } catch (error) {
        console.log("errr---------", error)
        toast.error(error.response.data.message)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})

export const resendOtpForLogin = createAsyncThunk("resendOtpForLogin" , async (body , {rejectWithValue , dispatch}) => {
    try {
      
        const { data, status } = await api.resendOtpForLogin(body);
  

        console.log("daaaata",data)

        if (status === 200) {
            toast.success("otp send successfully")
            return data.data; }
        
    } catch (error) {
        toast.error(error.response.data.message)
        console.log("errr---------", error)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})



export const updateProfileDetails = createAsyncThunk("updateProfileDetails" , async (body , {rejectWithValue , dispatch}) => {
    try {
      
        const { data, status } = await api.updateProfileDetails(body);
  

        console.log("daaaata",data)

        if (status === 200) {
            dispatch(setUser(data.data));
            return data.data; }
        
    } catch (error) {
        toast.error(error.response.data.message)
        console.log("errr---------", error)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})



export const signUpWithGoogle = createAsyncThunk("signUpWithGoogle" , async (body , {rejectWithValue , dispatch}) => {
    try {
        const { data, status } = await api.signUpWithGoogle(body);
  

        console.log("daaaata",data)

        if (status === 200) {
            localStorage.setItem("misseymoppettoken",data?.data?.token)
            dispatch(setUser(data.data));
            toast.success(data.data.message)
        }
        
    } catch (error) {
        console.log("errr---------", error)
        toast.error(error.response.data.message)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})


export const loginWithGoogle = createAsyncThunk("loginWithGoogle" , async (body , {rejectWithValue , dispatch}) => {
    try {
        const { data, status } = await api.loginWithGoogle(body);
  

        console.log("daaaata",data)

        if (status === 200) {
            localStorage.setItem("misseymoppettoken",data?.data?.token)
            dispatch(setUser(data.data));
            toast.success(data.data.message)
        }
        
    } catch (error) {
        console.log("errr---------", error)
        toast.error(error.response.data.message)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})



export const contactUs = createAsyncThunk("contactUs" , async (body , {rejectWithValue , dispatch}) => {
    dispatch(
        setData({
          name: "loading",
          value: true,
        })
      );
      dispatch(
        setData({
          name: "error",
          value: null,
        })
      );
    try {


        const { data, status } = await api.contactUs(body);
  
    

        console.log("daaaata",data)

        if (status === 200) {
            dispatch(
                setData({
                  name: "loading",
                  value: false,
                })
              );
            toast.success(data.data.message)
            return
        }
        
    } catch (error) {
        dispatch(
            setData({
              name: "loading",
              value: false,
            })
          );
          dispatch(
            setData({
              name: "error",
              value:
                error.response.data.message ||
                "'Something went wrong. Please try again later.'",
            })
          );
        console.log("errr---------", error)
        toast.error(error.response.data.message)
        return rejectWithValue(error.response.data.message || "'Something went wrong. Please try again later.'")
    }
})





const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{
        setUser:(state,action) => {
            state.data = action.payload;
        },
        setData: (state, action) => {
            state[action.payload.name] = action.payload.value;
          },
    }
})

export const {setUser,setData} = userSlice.actions
export default userSlice.reducer